import React from "react";
import CButton from "../../../../components/general/CButton";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { GooglePlaySvg } from "../../../../assets/icons";
import Helper from "../../../../utils/Helper";

const HeroSection = ({ title, desc, img }) => {
  return (
    <section id={"hero"} className={"md:h-[85vh]"}>
      <div className={"h-0 md:h-10 bg-white w-full"} />

      <div className={"flex_row-center px-5 md:px-12  "}>
        {/*  text  */}
        <div className={"flex-1"}>
          <h1 className={"font-medium leading-[60px] lg:w-4/5"}>{title}</h1>

          <p className={"font-rubik md:w-4/5 my-3 md:my-8"}>{desc}</p>

          {/*buttons*/}
          <div className={"flex_row gap-3 my-5 md:my-10"}>
            <CButton
              handleClick={Helper.openAppLink}
              className={"flex_row-center text-sm h-12 gap-2 px-6"}
            >
              <MdOutlineFileDownload className={"text-xl"} />

              <span>Download Rafu</span>
            </CButton>

            <CButton
              className={
                "flex_row-center text-sm h-12 gap-2 bg-white border border-primary px-7"
              }
              handleClick={Helper.handleCall}
            >
              <IoCallOutline className={"text-xl text-primary"} />
              <span className={"text-primary"}>Talk to Us</span>
            </CButton>
          </div>

          <div className={"md:mt-24"}>
            <p>Rafu is available for download</p>
          </div>

          <CButton
            handleClick={Helper.openAppLink}
            className={"bg-transparent mt-3 h-12 p-0 px-0 py-0 "}
          >
            <GooglePlaySvg />
          </CButton>
        </div>
        <div className={"hidden md:flex flex-1"}>
          <img
            className={"md:w-11/12 object-contain mx-auto"}
            src={img}
            alt={"hero-stock"}
          />
        </div>
      </div>

      <div className={"h-16 bg-white w-full"} />
    </section>
  );
};

export default HeroSection;
