import React from "react";
import CButton from "../../../components/general/CButton";
import { GooglePlaySvg } from "../../../assets/icons";
import Helper from "../../../utils/Helper";

const Try = () => {
  return (
    <div
      className={
        "flex_row-center flex-col h-[50vh] gradient relative overflow-hidden"
      }
    >
      <img src={"/mask-bg.png"} className={"absolute inset-0"} alt={"mask"} />

      <h2 className={"font-barlow text-3xl text-white font-semibold"}>
        {" "}
        Try Rafu for Free Today
      </h2>

      <p
        className={
          " md:w-1/2 lg:w-5/12 mx-auto text-white font-medium my-6 text-center"
        }
      >
        Join the new way to sell from a shelf you have rented at the comfort of
        your home, tracking inventory, sales and deliveries from the app. If you
        own a store with shelves Rafu enables you to make sales and update
        inventory, receive rent and make sales dispatches
      </p>

      <div className={"flex_row gap-5 mt-5 z-10"}>
        <CButton
          className={"mt-3 px-0 py-0 p-10 bg-transparent "}
          handleClick={Helper.openAppLink}
        >
          <GooglePlaySvg color={"#fff"} w={"156"} h={"50"} />
        </CButton>

        {/*<CButton className={"bg-transparent mt-3 h-12 p-0 px-0 py-0 "}>*/}
        {/*  <AppstoreSvg />*/}
        {/*</CButton>*/}
      </div>
    </div>
  );
};

export default Try;
