import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import CButton from "../../../../components/general/CButton";
import { GoArrowRight } from "react-icons/go";

const RafuForShelfOwners = () => {
  return (
    <section className={"min-h-[95vh] bg-white flex_row-center-y"}>
      <div
        className={
          "md:container md:flex_row-center px-5 md:px-0 mx-auto flex-1"
        }
      >
        <div className={"flex-1 mt-10 md:mt-0"}>
          <h1 className={"text-primary"}>Rafu for Shelf Owners</h1>

          <div className={"my-5 "}>
            <h3
              className={
                " py-3 capitalize text-black font-rubik font-medium text-xl"
              }
            >
              How Rafu Helps Shelf Managers like Grace
            </h3>
            <p className={"font-barlow"}>
              As a rent-a-shelf business manager, Grace would find Rafu to be an
              invaluable tool. It will streamline her tasks by keeping track of
              sales, payments and pickups, ensuring a smooth and efficient
              management process and enhancing her relationships with clients.
            </p>
          </div>

          <div className={"flex_row-center-y gap-4 mt-8 mb-3"}>
            <div className={"w-1.5 h-10 bg-primary"} />
            <p className={"text-lg"}>Grace is able to:</p>
          </div>

          <ul className={"flex_col gap-5"}>
            {abilities?.map((item) => (
              <li className={"flex_row gap-3 items-center"}>
                <IoCheckmarkSharp className={"text-xl text-primary"} />
                <div dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ul>

          <CButton
            className={
              "bg-transparent flex_row-center-y text-primary mt-8 gap-5 "
            }
          >
            <span className={"text-primary font-medium"}>
              Become a seller on Rafu
            </span>

            <GoArrowRight className={"text-xl text-primary"} />
          </CButton>
        </div>

        <div className={"md:p-10 mt-5 flex_row max-w-[550px]"}>
          <img className={"md:w-10/12 "} src={"/Grace.png"} alt={"seller"} />
        </div>
      </div>
    </section>
  );
};

export default RafuForShelfOwners;

const abilities = [
  `Accept and track her sellers inventory in two clicks`,
  `Sell on behalf of her sellers, automatically notifying her sellers of the sale `,
  `Get notified a pickup when her sellers sell and deliveries when they request for deliveries `,
  `Accept & Reconcile payments with her sellers `,
  `Collect rent from her sellers `,
];
