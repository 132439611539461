import React from "react";

const CButton = ({ className, handleClick, text, children }) => {
  return (
    <button onClick={handleClick} className={`btn ${className}`}>
      {text || children}
    </button>
  );
};

export default CButton;
