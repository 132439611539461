import React from "react";

const Wallet = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8733 19.5835H30.3833C28.01 19.5835 26.2483 21.3485 26.2483 23.3335C26.2483 25.3185 28.01 27.0835 30.3817 27.0835H34.9117C35.255 27.0618 35.4033 26.8302 35.415 26.6902V19.9768C35.4033 19.8368 35.255 19.6052 34.9117 19.5852L34.8733 19.5835ZM34.75 17.0835C34.86 17.0835 34.9667 17.0835 35.0667 17.0902C36.5167 17.1785 37.8017 18.2735 37.91 19.8035C37.9167 19.9035 37.9167 20.0118 37.9167 20.1118V26.5552C37.9167 26.6552 37.9167 26.7635 37.91 26.8635C37.8017 28.3935 36.5167 29.4885 35.065 29.5785C34.9667 29.5835 34.86 29.5835 34.7483 29.5835H30.385C26.81 29.5835 23.75 26.8702 23.75 23.3335C23.75 19.7968 26.81 17.0835 30.3833 17.0835H34.7217H34.75Z"
        fill="#13A89E"
      />
      <path
        d="M31.6663 23.3332C31.6663 23.7752 31.4907 24.1991 31.1782 24.5117C30.8656 24.8242 30.4417 24.9998 29.9997 24.9998C29.5576 24.9998 29.1337 24.8242 28.8212 24.5117C28.5086 24.1991 28.333 23.7752 28.333 23.3332C28.333 22.8911 28.5086 22.4672 28.8212 22.1547C29.1337 21.8421 29.5576 21.6665 29.9997 21.6665C30.4417 21.6665 30.8656 21.8421 31.1782 22.1547C31.4907 22.4672 31.6663 22.8911 31.6663 23.3332Z"
        fill="#13A89E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7497 17.0835C35.2278 17.0655 35.7038 17.1567 36.1413 17.3502C35.963 14.6735 35.4713 12.6102 33.9313 11.0685C32.683 9.82186 31.1013 9.26853 29.148 9.0052L29.078 8.99686C29.0606 8.98364 29.0428 8.97086 29.0247 8.95853L22.798 4.8302C21.7179 4.12533 20.4561 3.75 19.1663 3.75C17.8766 3.75 16.6148 4.12533 15.5347 4.8302L9.30967 8.95853C9.29101 8.97083 9.27267 8.98361 9.25467 8.99686L9.18468 9.0052C7.23134 9.26853 5.64967 9.82186 4.40134 11.0685C3.15467 12.3169 2.60134 13.8985 2.33801 15.8519C2.08301 17.7519 2.08301 20.1769 2.08301 23.2402V23.4269C2.08301 26.4902 2.08301 28.9169 2.33801 30.8152C2.60134 32.7685 3.15467 34.3502 4.40134 35.5985C5.64967 36.8452 7.23134 37.3985 9.18468 37.6619C11.0847 37.9169 13.5097 37.9169 16.573 37.9169H21.7597C24.823 37.9169 27.2497 37.9169 29.148 37.6619C31.1013 37.3985 32.683 36.8452 33.9313 35.5985C35.4713 34.0569 35.963 31.9935 36.1413 29.3152C35.8047 29.4652 35.4413 29.5552 35.0647 29.5785C34.9663 29.5835 34.8597 29.5835 34.748 29.5835H33.613C33.423 31.8202 32.9947 33.0002 32.163 33.8302C31.458 34.5352 30.4913 34.9585 28.8147 35.1835C27.103 35.4135 24.8447 35.4169 21.6663 35.4169H16.6663C13.488 35.4169 11.2313 35.4135 9.51634 35.1835C7.84134 34.9585 6.87467 34.5352 6.16967 33.8302C5.46467 33.1252 5.04134 32.1585 4.81634 30.4819C4.58634 28.7702 4.58301 26.5119 4.58301 23.3335C4.58301 20.1552 4.58634 17.8985 4.81634 16.1835C5.04134 14.5085 5.46467 13.5419 6.16967 12.8369C6.87467 12.1319 7.84134 11.7085 9.51801 11.4835C11.2313 11.2535 13.488 11.2502 16.6663 11.2502H21.6663C24.8447 11.2502 27.103 11.2535 28.8163 11.4835C30.4913 11.7085 31.458 12.1319 32.163 12.8369C32.9947 13.6669 33.423 14.8485 33.613 17.0835H34.7213H34.7497ZM16.573 8.7502H21.7597C22.6213 8.7502 23.4313 8.7502 24.193 8.7552L21.4163 6.91353C20.083 6.0302 18.2497 6.0302 16.9163 6.91353L14.138 8.7552C14.9013 8.7502 15.7113 8.7502 16.5713 8.7502"
        fill="#13A89E"
      />
      <path
        d="M10 15.4165C9.66848 15.4165 9.35054 15.5482 9.11612 15.7826C8.8817 16.017 8.75 16.335 8.75 16.6665C8.75 16.998 8.8817 17.316 9.11612 17.5504C9.35054 17.7848 9.66848 17.9165 10 17.9165H16.6667C16.9982 17.9165 17.3161 17.7848 17.5505 17.5504C17.785 17.316 17.9167 16.998 17.9167 16.6665C17.9167 16.335 17.785 16.017 17.5505 15.7826C17.3161 15.5482 16.9982 15.4165 16.6667 15.4165H10Z"
        fill="#13A89E"
      />
      <path
        d="M31.6663 23.3332C31.6663 23.7752 31.4907 24.1991 31.1782 24.5117C30.8656 24.8242 30.4417 24.9998 29.9997 24.9998C29.5576 24.9998 29.1337 24.8242 28.8212 24.5117C28.5086 24.1991 28.333 23.7752 28.333 23.3332C28.333 22.8911 28.5086 22.4672 28.8212 22.1547C29.1337 21.8421 29.5576 21.6665 29.9997 21.6665C30.4417 21.6665 30.8656 21.8421 31.1782 22.1547C31.4907 22.4672 31.6663 22.8911 31.6663 23.3332Z"
        fill="#13A89E"
      />
    </svg>
  );
};

export default Wallet;
