import { routes } from "./utils/routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <div className="App min-w-[100vw]">
        <Routes>
          {routes.length > 0 &&
            routes.map((route, i) => (
              <Route key={i} path={route.path} exact element={route.element} />
            ))}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
