import React from "react";

const BookShelf = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 0C6.58152 0 6.89946 0.131696 7.13388 0.366117C7.3683 0.600537 7.5 0.918479 7.5 1.25V5H32.5V1.25C32.5 0.918479 32.6317 0.600537 32.8661 0.366117C33.1005 0.131696 33.4185 0 33.75 0C34.0815 0 34.3995 0.131696 34.6339 0.366117C34.8683 0.600537 35 0.918479 35 1.25V38.75C35 39.0815 34.8683 39.3995 34.6339 39.6339C34.3995 39.8683 34.0815 40 33.75 40C33.4185 40 33.1005 39.8683 32.8661 39.6339C32.6317 39.3995 32.5 39.0815 32.5 38.75V37.5H7.5V38.75C7.5 39.0815 7.3683 39.3995 7.13388 39.6339C6.89946 39.8683 6.58152 40 6.25 40C5.91848 40 5.60054 39.8683 5.36612 39.6339C5.1317 39.3995 5 39.0815 5 38.75V1.25C5 0.918479 5.1317 0.600537 5.36612 0.366117C5.60054 0.131696 5.91848 0 6.25 0ZM7.5 35H32.5V27.5H7.5V35ZM7.5 25H32.5V17.5H7.5V25ZM7.5 15H32.5V7.5H7.5V15Z"
        fill="#13A89E"
      />
    </svg>
  );
};

export default BookShelf;
