import React from "react";
import RafuTypeItem from "../about/RafuTypeItem";
import { types } from "../about";
import {
  AppstoreAddSvg,
  BookShelfSvg,
  DeliverySvg,
  WalletSvg,
} from "../../../../assets/icons";
import FeatureItem from "./FeatureItem";

const Features = ({ current = 0 }) => {
  return (
    <section
      id={"features"}
      className={"min-h-[95vh] bg-white px-5 md:px-0 my-16 md:my-0"}
    >
      <div className={"md:container mx-auto md:flex_row-center h-full"}>
        <div className={"flex-1 flex_row-center flex-col"}>
          <h1 className={"text-black mb-8"}>Top Rafu features</h1>

          <p className={"md:w-3/4"}>
            Rafu empowers you to cultivate your online business worry-free.
            Embrace the convenience of tracking inventory, sales, pickups, and
            arranging deliveries right at your fingertips.
          </p>

          <div className={"mt-20 md:mx-auto w-full md:w-3/4 flex_col gap-5"}>
            {types?.map((t, ind) => (
              <RafuTypeItem
                showRightArrow={false}
                isCurrent={ind === current}
                key={ind}
                title={t}
              />
            ))}
          </div>
        </div>

        <div className={"flex-1 grid md:grid-cols-2 gap-5 mt-10 md:mt-0"}>
          {features?.map(({ icon, title, description }, ind) => (
            <FeatureItem icon={icon} title={title} body={description} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;

const features = [
  {
    icon: <DeliverySvg />,
    title: "Track Sales & Deliveries",
    description:
      "Join the new way to sell from a shelf you have rented at the comfort of your home, tracking inventory, sales and deliveries from the app.",
  },
  {
    icon: <AppstoreAddSvg />,
    title: "Manage Inventory in Stock",
    description:
      "Join the new way to sell from a shelf you have rented at the comfort of your home, tracking inventory, sales and deliveries from the app.",
  },
  {
    icon: <WalletSvg />,
    title: "Reconcile Payments with Vendors",
    description:
      "Join the new way to sell from a shelf you have rented at the comfort of your home, tracking inventory, sales and deliveries from the app.",
  },
  {
    icon: <BookShelfSvg />,
    title: "Pay Shelf Rent",
    description:
      "Join the new way to sell from a shelf you have rented at the comfort of your home, tracking inventory, sales and deliveries from the app.",
  },
];
