import React from "react";
import Layout from "../../layout";
import {
  AboutSection,
  FeaturesSection,
  HeroSection,
  StatsSection,
  TrySection,
} from "../stock/components";
import RafuForShelfOwners from "./components/rafu_for_shelf_owners";
import Reviews from "./components/rafu_for_shelf_owners/Reviews";

const Shelf = () => {
  return (
    <Layout>
      {/*  Hero Section  */}
      <HeroSection
        title={
          <>
            <span className={"text-primary font-playfair font-medium"}>
              Save time{" "}
            </span>{" "}
            and{" "}
            <span className={"text-primary font-playfair font-medium"}>
              Eliminate the hassle{" "}
            </span>
            running your Rent a shelf business
          </>
        }
        desc={
          "Rafu makes running a store easy. It helps rent a shelf owner and shop attendants serve customers faster, track deliveries, efficiently reconcile payments  and gives sellers constant sales updates."
        }
        img={"/hero-shelf.png"}
      />

      {/*    stats    */}
      <StatsSection />

      {/*    About section   */}
      <AboutSection />

      {/*    Rafu for sellers section   */}
      <RafuForShelfOwners />

      {/*    Rafu features   */}
      <FeaturesSection current={1} />

      {/*    Try rafu section   */}
      <TrySection />

      {/*    Reviews Section   */}
      <Reviews />
    </Layout>
  );
};

export default Shelf;
/* Rectangle 1011 */
