import React from "react";
import { FiMapPin } from "react-icons/fi";
import { BsQuote } from "react-icons/bs";

const Reviews = () => {
  return (
    <div className={"min-h-[60vh] flex_row-center flex-col py-16 md:py-0 px-5"}>
      <h2 className={"capitalize font-rubik font-semibold text-xl md:text-3xl"}>
        You are not the first nor the last
      </h2>

      <p
        className={
          "font-rubik sm:w-3/4 md:w-1/2 lg:w-1/3 my-5 text-center font-light"
        }
      >
        Rafu's early adopters have contributed feedback that has been so
        resourceful in helping refine the usability and customer experience.
      </p>

      {/*  Reviews cards  */}
      <div
        className={
          "grid grid-cols-1 md:grid-cols-2 font-rubik w-full md:max-w-[1200px] mx-auto gap-8 mt-8 md:mt-12"
        }
      >
        <ReviewCardItem />

        <ReviewCardItem />
      </div>
    </div>
  );
};

export default Reviews;

const ReviewCardItem = () => (
  <div
    className={"rounded-xl border border-gray-100 p-4 flex_row gap-5 w-full"}
  >
    <img
      src={"/reviewer.png"}
      className={"h-20 md:h-28 w-20 md:w-28"}
      alt={"reviewer"}
    />

    <div className={"flex-1 flex_col gap-2"}>
      <div className={"flex_row"}>
        <div className={"flex-col md:flex_row items-center gap-3 flex-1"}>
          <h6 className={"font-semibold text-primary text-base"}>
            Peter Njenga
          </h6>
          <div
            className={
              "h-1 w-1 rounded-full bg-gray-800 self-end mb-1.5 hidden md:flex"
            }
          />
          <span className={"text-sm text-gray-500"}>Seller on Rafu</span>
        </div>

        <BsQuote className={"rotate-180 text-primary text-lg"} />
      </div>
      <div className={"flex_row items-center gap-2"}>
        <FiMapPin className={"text-gray-500"} />

        <span className={"text-sm text-gray-500"}>Nairobi, Kenya</span>
      </div>
      <p className={"mt-1 text-sm"}>
        We put in care into every single relationship with our clients so that
        their property hunt joints their positive memories.
      </p>
    </div>
  </div>
);
