import React from "react";
import { Link, useLocation } from "react-router-dom";
import CButton from "../general/CButton";
import { IoCallOutline } from "react-icons/io5";
import { SUPPORT_PHONE } from "../../constants/defaults";
import { MdOutlineFileDownload } from "react-icons/md";
import Helper from "../../utils/Helper";

const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <div
      id={"nav"}
      className={"px-5 lg:px-14 h-24 flex_row-center-y justify-between "}
    >
      {/* logo */}
      <img className={"h-11 md:h-14"} src={"/logo.png"} alt={"rafu-logo"} />

      {/*nav links*/}
      <div className={"flex_row gap-5"}>
        {links?.map((link, ind) => {
          const isCurrent = pathname === link?.path;

          return (
            <Link to={link?.path} key={ind}>
              <span
                className={`font-barlow ${
                  isCurrent ? "text-primary font-medium" : "text-black"
                } text-sm md:text-base`}
              >
                {link?.name}
              </span>
            </Link>
          );
        })}
      </div>

      {/*  buttons  */}
      <div className={"hidden md:flex_row gap-3"}>
        <CButton
          handleClick={Helper.handleCall}
          className={"flex_row-center text-sm h-12 gap-2"}
        >
          <IoCallOutline className={"text-xl"} />
          <span>{SUPPORT_PHONE}</span>
        </CButton>

        <CButton
          handleClick={Helper.openAppLink}
          className={"flex_row-center text-sm h-12 gap-2 px-6"}
        >
          <MdOutlineFileDownload className={"text-xl"} />

          <span>Download</span>
        </CButton>
      </div>
    </div>
  );
};

export default Navbar;

const links = [
  {
    name: "For Online Sellers",
    path: "/",
  },
  {
    name: "For Rent a Shelf",
    path: "/shelf",
  },
  // {
  //   name: "Contact us",
  //   path: "/contact",
  // },
];
