import React from "react";

const FeatureItem = ({ icon, title, body }) => {
  return (
    <div
      className={
        "px-8 py-12 bg-zinc-100 rounded-2xl hover:bg-primary hover:text-white text-black group"
      }
    >
      <div className={"flex_row"}>
        <div
          className={
            "p-3 rounded-full border border-primary group-hover:bg-white"
          }
        >
          {icon}
        </div>
      </div>

      <h5 className={"mt-10 mb-3 font-semibold text-inherit"}>{title}</h5>

      <p className={"text-inherit group-hover:font-medium"}>{body}</p>
    </div>
  );
};

export default FeatureItem;
