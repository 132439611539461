import { Stock } from "../pages";
import Shelf from "../pages/shelf";

export const routes = [
  {
    element: <Stock />,
    path: "/",
  },
  {
    element: <Shelf />,
    path: "/shelf",
  },
];
