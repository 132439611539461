import React from "react";

const Stats = () => {
  return (
    <section className={"px-5 md:px-12 gradient "}>
      <div
        className={"md:w-[1280px] mx-auto py-20 md:flex_row justify-between"}
      >
        <div className={"flex_row-center-y gap-8"}>
          <div className={"text-white"}>
            <h3 className={"font-bold font-barlow text-3xl"}>25</h3>
            <p className={"text-lg"}>
              Rent shelves <br className={"hidden md:flex"} /> using Rafu
            </p>
          </div>

          <div className={"w-1.5 h-16 md:h-24 bg-white"} />

          <div className={"text-white"}>
            <h3 className={"font-bold font-barlow text-3xl"}>1,947</h3>
            <p className={"text-lg"}>Sellers on Rafu</p>
          </div>
        </div>

        <div className={"mt-5 md:mt-0"}>
          <p className={"font-rubik text-xl md:text-2xl leading-10 text-white"}>
            Join the growing community of{" "}
            <span className={"font-bold"}>Sellers</span> using{" "}
            <br className={"hidden md:flex"} /> Rafu to Manage the shelves they
            rent
          </p>
        </div>
      </div>
    </section>
  );
};

export default Stats;
