import React from "react";
import RafuTypeItem from "./RafuTypeItem";

const About = () => {
  return (
    <section id={"about"} className={"min-h-[95vh] bg-white flex_row-center"}>
      <div
        className={
          "pt-10 md:pt-0 md:flex_row md:container mx-auto rounded-[40px] bg-slate-50"
        }
      >
        <div className={"flex-1 flex_row-center flex-col"}>
          <h1 className={"text-black "}>What Is Rafu?</h1>

          <p className={"font-rubik w-10/12 my-8 text-slate-500"}>
            Rafu offers you, as the seller, a hassle-free platform to expand
            your online business with absolute peace of mind. With Rafu, you can
            effortlessly monitor your inventory, track in-store sales, view
            pickups, and even request deliveries, all from the convenience of
            your mobile device.
          </p>

          <div className={"mt-10 mx-auto w-10/12 flex_col gap-5"}>
            {types?.map((t, ind) => (
              <RafuTypeItem key={ind} title={t} />
            ))}
          </div>
        </div>
        <div className={"flex-1 h-full py-10"}>
          <img
            className={"object-contain md:w-11/12"}
            src={"/about.png"}
            alt={"about"}
          />
        </div>
      </div>
    </section>
  );
};

export default About;

export const types = ["Rafu for Online Sellers", "Rafu for Shelf Owners"];
