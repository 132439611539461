import React from "react";
import { Navbar } from "../components";
import Footer from "../components/footer";

const Layout = ({ children }) => {
  return (
    <div className={"bg-white "}>
      {/*  navbar  */}
      <Navbar />

      {/*  body  */}
      {children}

      {/*    footer   */}
      <Footer />
    </div>
  );
};

export default Layout;
