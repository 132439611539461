import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import CButton from "../../../../components/general/CButton";
import { GoArrowRight } from "react-icons/go";

const RafuForSellers = () => {
  return (
    <section
      className={"min-h-[95vh] bg-white flex_row-center-y my-16 md:my-0"}
    >
      <div className={"md:container md:flex_row-center mx-auto flex-1"}>
        <div className={"p-5 md:p-10 flex_row"}>
          <img className={"md:w-10/12"} src={"/seller.png"} alt={"seller"} />
        </div>
        <div className={"flex-1 px-5 md:px-0"}>
          <h1 className={"text-primary"}>Rafu for Sellers</h1>

          <div className={"my-5 "}>
            <h3
              className={
                " py-3 capitalize text-black font-rubik font-medium text-xl"
              }
            >
              How Rafu Helps sellers like jeff
            </h3>
            <p className={"font-barlow"}>
              Jeff is an online seller stocking perfumes and gents clothing
              which he markets on his social media profiles and sells in person
              and from a shelf in town
            </p>
          </div>

          <div className={"flex_row-center-y gap-4 mt-8 mb-3"}>
            <div className={"w-1.5 h-10 bg-primary"} />
            <p className={"text-lg"}>Jeff is able to:</p>
          </div>

          <ul className={"flex_col gap-5"}>
            {abilities?.map((item) => (
              <li className={"flex_row gap-3 items-center"}>
                <IoCheckmarkSharp className={"text-xl text-primary"} />
                <div dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ul>

          <CButton
            className={
              "bg-transparent flex_row-center-y text-primary mt-8 gap-5 "
            }
          >
            <span className={"text-primary font-medium"}>
              Become a seller on Rafu
            </span>

            <GoArrowRight className={"text-xl text-primary"} />
          </CButton>
        </div>
      </div>
    </section>
  );
};

export default RafuForSellers;

const abilities = [
  `Track his perfume <strong>Stock</strong> and get notified when he’s almost running out`,
  `<strong>Sell</strong> perfume from his app, automatically notifying the shelf attendant`,
  `Get notification when a <strong>Sale</strong> happens at the shelf `,
  `Request and track <strong>Delivery status</strong> of orders `,
  `Collect Mpesa <strong>Payments</strong>, reconcile POD and Cash payments`,
];
