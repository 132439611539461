import React from "react";
import Layout from "../../layout";
import {
  AboutSection,
  FeaturesSection,
  HeroSection,
  RafuForSellersSection,
  StatsSection,
  TrySection,
} from "./components";
import Reviews from "../shelf/components/rafu_for_shelf_owners/Reviews";

const Stock = () => {
  return (
    <Layout>
      {/*  Hero Section  */}
      <HeroSection
        title={
          <>
            Track you rent a shelf{" "}
            <h1 className={"text-primary"}>Stock, Sales and Payments </h1> In
            realtime{" "}
          </>
        }
        desc={
          "Embrace the convenience of tracking inventory, sales, pickups, and arranging deliveries right at your fingertips."
        }
        img={"/hero-stock.png"}
      />

      {/*    stats    */}
      <StatsSection />

      {/*    About section   */}
      <AboutSection />

      {/*    Rafu for sellers section   */}
      <RafuForSellersSection />

      {/*    Rafu features   */}
      <FeaturesSection />

      {/*    Try rafu section   */}
      <TrySection />

      {/*    reviews section   */}
      <Reviews />
    </Layout>
  );
};

export default Stock;
/* Rectangle 1011 */
