import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleClick = (path) => {
    if (path?.startsWith("/")) {
      navigate(path);
      document?.getElementById("nav")?.scrollIntoView({
        behavior: "smooth",
      });
    } else if (path.startsWith("https")) {
      window.open(path);
    } else {
      document?.getElementById(path)?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <section className={"py-16"}>
      <div
        className={"px-5 md:px-0 md:flex_row md:container mx-auto md:gap-10"}
      >
        {/*  Rafu zetu  */}
        <div className={"flex-1"}>
          <img
            className={"w-20 object-contain"}
            src={"/logo-outlined.png"}
            alt={"logo-outlined"}
          />

          <p className={"text-sm md:text-base mt-3 md:w-5/6"}>
            The right way to rent a shelf as a seller or owner. Rafu enables
            both vendors and sellers to meet their goals with increased
            convenience and trust
          </p>
        </div>

        <div className={"flex_row flex-1 my-8 md:my-0"}>
          {/*  Company  */}
          <div className={"flex-1"}>
            <h5 className={"text-xl font-semibold mb-5"}>Company</h5>

            <ul className={"flex_col gap-3"}>
              {company?.map((c, ind) => (
                <button
                  className={"text-left"}
                  onClick={() => handleClick(c?.link)}
                >
                  <li key={ind}>{c?.name}</li>
                </button>
              ))}
            </ul>
          </div>

          {/*  connect with us  */}
          <div className={"flex-1"}>
            <h5 className={"text-xl font-semibold mb-5"}>Connect with Us</h5>

            <ul className={"flex_col gap-2"}>
              {socialList?.map((item, ind) => (
                <a className={"z-10 p-3"} key={ind} href={item?.href}>
                  <li className={"flex_row gap-2 items-center cursor-pointer"}>
                    {item?.icon} {item?.name}
                  </li>
                </a>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div
        className={
          "mt-16 border-t border-gray-400 md:container mx-auto flex_col items-center py-8"
        }
      >
        <h6 className={"font-semibold"}>
          Copyright © {new Date().getFullYear()} Rafu Zetu
        </h6>

        <p className={"mt-3"}>
          Designed & Built by{" "}
          <a
            href={"https://www.glitexsolutions.co.ke/"}
            className={"text-primary underline"}
          >
            Glitex Solutions
          </a>
        </p>
      </div>
    </section>
  );
};

export default Footer;

const company = [
  {
    name: "What is Rafu",
    link: "about",
  },
  {
    name: " Rafu for Sellers",
    link: "/",
  },
  {
    name: "Rafu for Shelf Owners",
    link: "/shelf",
  },
  {
    name: "Features",
    link: "features",
  },
  {
    name: "Privacy & Terms",
    link: "https://privacyandterms.rafuzetu.com/",
  },
];

const socialList = [
  {
    icon: <FaFacebook className={"text-xl"} />,
    name: "Facebook",
    href: "https://web.facebook.com/profile.php?id=61556693919649",
  },

  {
    icon: <FaInstagram className={"text-xl"} />,
    name: "Instagram",
    href: "https://instagram.com/rafu_zetu",
  },
];
