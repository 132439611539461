import React from "react";

const AppstoreAdd = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.125 5.625H6.25C5.90625 5.625 5.625 5.90625 5.625 6.25V18.125C5.625 18.4688 5.90625 18.75 6.25 18.75H18.125C18.4688 18.75 18.75 18.4688 18.75 18.125V6.25C18.75 5.90625 18.4688 5.625 18.125 5.625ZM16.0938 16.0938H8.28125V8.28125H16.0938V16.0938ZM33.75 5.625H21.875C21.5312 5.625 21.25 5.90625 21.25 6.25V18.125C21.25 18.4688 21.5312 18.75 21.875 18.75H33.75C34.0938 18.75 34.375 18.4688 34.375 18.125V6.25C34.375 5.90625 34.0938 5.625 33.75 5.625ZM31.7188 16.0938H23.9062V8.28125H31.7188V16.0938ZM33.75 21.25H21.875C21.5312 21.25 21.25 21.5312 21.25 21.875V33.75C21.25 34.0938 21.5312 34.375 21.875 34.375H33.75C34.0938 34.375 34.375 34.0938 34.375 33.75V21.875C34.375 21.5312 34.0938 21.25 33.75 21.25ZM31.7188 31.7188H23.9062V23.9062H31.7188V31.7188ZM16.5625 27.8125H11.5625V22.8125C11.5625 22.6406 11.4219 22.5 11.25 22.5H9.375C9.20312 22.5 9.0625 22.6406 9.0625 22.8125V27.8125H4.0625C3.89062 27.8125 3.75 27.9531 3.75 28.125V30C3.75 30.1719 3.89062 30.3125 4.0625 30.3125H9.0625V35.3125C9.0625 35.4844 9.20312 35.625 9.375 35.625H11.25C11.4219 35.625 11.5625 35.4844 11.5625 35.3125V30.3125H16.5625C16.7344 30.3125 16.875 30.1719 16.875 30V28.125C16.875 27.9531 16.7344 27.8125 16.5625 27.8125Z"
        fill="#13A89E"
      />
    </svg>
  );
};

export default AppstoreAdd;
