import React from "react";
import { HiOutlinePlus } from "react-icons/hi";
import CButton from "../../../../components/general/CButton";
import { GoArrowRight } from "react-icons/go";
import { IoCheckmarkSharp } from "react-icons/io5";
import { COLORS } from "../../../../constants/Theme";

const RafuTypeItem = ({ title, isCurrent, showRightArrow = true }) => {
  return (
    <div
      className={`md:w-5/6 flex_row-center-y gap-5 rounded py-2 px-5`}
      style={{
        backgroundColor: isCurrent ? COLORS.primary + 15 : "white",
      }}
    >
      <button
        className={
          "h-12 w-12 border border-primary rounded-full flex_row-center"
        }
      >
        {isCurrent ? (
          <IoCheckmarkSharp className={"text-xl text-primary"} />
        ) : (
          <HiOutlinePlus className={"text-primary"} />
        )}
      </button>

      <div
        className={`flex-1 ${
          isCurrent ? "text-primary font-semibold" : "text-black"
        }`}
      >
        <span>{title}</span>
      </div>

      {showRightArrow && (
        <CButton className={"bg-transparent "}>
          <GoArrowRight className={"text-2xl text-black"} />
        </CButton>
      )}
    </div>
  );
};

export default RafuTypeItem;
